import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../config/firebase";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CodeBlock from "./code/CodeBlock";
import { Wrapper } from "./code/styles";

const options = {
    renderMark: {
        [MARKS.CODE]: (text) => {
            console.log("code");
            console.log(text);
            return (
                <Wrapper>
                    <CodeBlock
                        language={"javascript"}
                        codeString={text}
                        highlightedLines={[]}
                    />
                </Wrapper>
            );
        }
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <div>{children}</div>,
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            if (node.data.target.sys.contentType.sys.id === "embedForRichText")
                return (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: node.data.target.fields.embedVideo.html
                        }}
                    ></div>
                );
            else return <></>;
        }
    }
};
const SinglePost = () => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const { link } = useParams();
    const [post, SetPost] = useState({});

    const getPostDate = (str) => {
        let postDate = new Date(str);
        return `published on ${postDate.getDate()} ${
            months[postDate.getMonth()]
        }, ${postDate.getFullYear()}`;
    };

    useEffect(() => {
        (async () => {
            try {
                let query = await db
                    .collection("BlogPosts")
                    .where("link", "==", link)
                    .get();
                if (!query.empty) {
                    SetPost(query.docs[0].data());
                } else {
                    SetPost(null);
                }
            } catch (err) {
                console.error(err);
            }
        })();
        console.log(MARKS.CODE);
    }, [link]);
    return (
        <>
            <div className="flex justify-center mt-8">
                <div className="w-3/4">
                    {post && Object.keys(post).length ? (
                        <>
                            <h1 className="ml-2 text-5xl font-extrabold">
                                {post.title}
                            </h1>
                            <div className="w-full mt-4 relative">
                                <img
                                    src={post.coverImage.fields.file.url}
                                    alt={post.coverImage.fields.title}
                                    className="w-full rounded"
                                ></img>
                                <div className="w-48 h-48 absolute bottom-0 left-0 ml-8 mb-6">
                                    <img
                                        src={post.displayImage.fields.file.url}
                                        alt={post.displayImage.fields.title}
                                        className="w-full h-full object-cover rounded-md"
                                    ></img>
                                </div>
                            </div>
                            <div className="mt-4 mr-6">
                                <p className="text-right text-base italic">
                                    {getPostDate(post.sys.createdAt)}
                                </p>
                            </div>
                            <div className="my-2">
                                {documentToReactComponents(
                                    post.content,
                                    options
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default SinglePost;

