import React, { useState, useContext, useEffect } from 'react'
import { awardStatus, labels } from '../../../../utils/constants';
import AddButton from './AddButton';
import Loader from '../../../../utils/Loader';
import SelectInput from '../SelectInput';
import { dataContext } from '../../DataContextProvider';


export default function FilterSelect({ indexName, inputFilterType, filterInputs, setFilterInputs, index }) {
    const [inputGenre, setInputGenre] = useState('Action')
    const [inputCountry, setInputCountry] = useState({ name: 'Andorra', code: 'AD' })
    const [inputDepartment, setInputDepartment] = useState('Camera')
    const [inputAwardStatus, setInputAwardStatus] = useState('won');
    const [inputAwardYearType, setInputAwardYearType] = useState('individual')
    const [inputAwardYear, setInputAwardYear] = useState('2020')
    const [inputYearStart, setInputYearStart] = useState('1927/28')
    const [inputYearEnd, setInputYearEnd] = useState('2020')
    const [inputBrandName, setInputBrandName] = useState('Academy Awards')
    const [inputMainAward, setInputMainAward] = useState('Oscar')
    const [inputMainCategory, setInputMainCategory] = useState('Best Picture')
    const [inputLabels, setInputLabels] = useState('Featured')

    const {
        awardBrandNames,
        mainAwards,
        mainCategories,
        awardYears,
        departments,
        countries,
        genres
    } = useContext(dataContext)

    useEffect(() => {
        if (mainAwards[`${inputBrandName}`])
            setInputMainAward(mainAwards[`${inputBrandName}`][0])

    }, [inputBrandName, mainAwards])

    useEffect(() => {
        if (mainCategories[`${inputMainAward}`])
            setInputMainCategory(mainCategories[`${inputMainAward}`][0])
    }, [inputMainAward, mainCategories])

    return (
        indexName ?
            <>
                {
                    inputFilterType === 'genres.name' &&
                    <>
                        <SelectInput
                            id="genres"
                            inputName={inputGenre}
                            setInputName={setInputGenre}
                            options={
                                genres[`${indexName}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <AddButton
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            inputName={inputGenre}
                            filterName='genres.name'
                            index={index}
                        />
                    </>
                }
                {
                    inputFilterType === 'origin_country' &&
                    <>
                        <SelectInput
                            id="origin_country"
                            inputName={inputCountry}
                            setInputName={setInputCountry}
                            options={
                                countries.map(temp => ({ value: temp.code, name: temp.name }))
                            }
                            index={index}
                        />
                        <AddButton
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            inputName={inputCountry}
                            filterName='origin_country'
                            index={index}
                        />
                    </>
                }
                {
                    inputFilterType === 'known_for_department' &&
                    <>
                        <SelectInput
                            id="known_for_department"
                            inputName={inputDepartment}
                            setInputName={setInputDepartment}
                            options={
                                departments.map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <AddButton
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            inputName={inputDepartment}
                            filterName='known_for_department'
                            index={index}
                        />
                    </>
                }
                {
                    inputFilterType === 'status' &&
                    <>
                        <SelectInput
                            id="status"
                            inputName={inputAwardStatus}
                            setInputName={setInputAwardStatus}
                            options={
                                awardStatus.map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <AddButton
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            inputName={inputAwardStatus}
                            filterName='status'
                            index={index}
                        />
                    </>
                }
                {
                    inputFilterType === 'labels' &&
                    <>
                        <SelectInput
                            id="labels"
                            inputName={inputLabels}
                            setInputName={setInputLabels}
                            options={
                                labels.map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <AddButton
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            inputName={inputLabels}
                            filterName='labels'
                            index={index}
                        />
                    </>
                }
                {
                    inputFilterType === 'awardYear' &&
                    <>
                        <SelectInput
                            id="awardYear"
                            inputName={inputAwardYearType}
                            setInputName={setInputAwardYearType}
                            options={[
                                { value: "individual", name: "individual" },
                                { value: "range", name: "range" }
                            ]}
                            index={index}
                        />
                        {
                            inputAwardYearType === 'individual' &&
                            <>
                                <SelectInput
                                    id="awardYear"
                                    inputName={inputAwardYear}
                                    setInputName={setInputAwardYear}
                                    options={
                                        awardYears.map(temp => ({ value: temp, name: temp }))
                                    }
                                    index={index}
                                />
                                <AddButton
                                    filterInputs={filterInputs}
                                    setFilterInputs={setFilterInputs}
                                    inputName={inputAwardYear}
                                    filterName='awardYear'
                                    index={index}
                                />
                            </>
                        }
                        {
                            inputAwardYearType === 'range' &&
                            <>
                                <SelectInput
                                    id="yearStart"
                                    inputName={inputYearStart}
                                    setInputName={setInputYearStart}
                                    options={
                                        awardYears.map(temp => ({ value: temp, name: temp }))
                                    }
                                    index={index}
                                />
                                <SelectInput
                                    id="yearEnd"
                                    inputName={inputYearEnd}
                                    setInputName={setInputYearEnd}
                                    options={
                                        awardYears.map(temp => ({ value: temp, name: temp }))
                                    }
                                    index={index}
                                />
                                <button
                                    type="button"
                                    className=" ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => {
                                        let temp = filterInputs[index]
                                        let filterName = 'awardYear'
                                        let inputName = `${inputYearStart} TO ${inputYearEnd}`
                                        if (!([filterName] in temp)) {
                                            temp[filterName] = []
                                            temp[filterName].push(inputName);
                                        } else if (!(temp[filterName].includes(inputName))) {
                                            temp[filterName].push(inputName);
                                        }
                                        setFilterInputs({ ...filterInputs, [`${index}`]: temp })
                                    }}
                                >
                                    Add +
                                </button>
                            </>
                        }
                    </>
                }
                {
                    inputFilterType === 'categories.lvl0' &&
                    <>
                        <SelectInput
                            id="categorieslvl0"
                            inputName={inputBrandName}
                            setInputName={setInputBrandName}
                            options={
                                awardBrandNames[`${indexName}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <AddButton
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            inputName={inputBrandName}
                            filterName='categories.lvl0'
                            index={index}
                        />
                    </>
                }
                {
                    inputFilterType === 'categories.lvl1' &&
                    <>
                        <SelectInput
                            id="categorieslvl1BrandNames"
                            inputName={inputBrandName}
                            setInputName={setInputBrandName}
                            options={
                                awardBrandNames[`${indexName}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <SelectInput
                            id="categories.lvl1MainAwards"
                            inputName={inputMainAward}
                            setInputName={setInputMainAward}
                            options={
                                mainAwards[`${inputBrandName}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <button
                            type="button"
                            className=" ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                                let temp = filterInputs[index]
                                let filterName = 'categories.lvl1'
                                let inputName = `${inputBrandName} > ${inputMainAward}`
                                if (!([filterName] in temp)) {
                                    temp[filterName] = []
                                    temp[filterName].push(inputName);
                                } else if (!(temp[filterName].includes(inputName))) {
                                    temp[filterName].push(inputName);
                                }
                                setFilterInputs({ ...filterInputs, [`${index}`]: temp })
                            }}
                        >
                            Add +
                        </button>
                    </>
                }
                {
                    inputFilterType === 'categories.lvl2' &&
                    <>
                        <SelectInput
                            id="categories.lvl2BrandNames"
                            inputName={inputBrandName}
                            setInputName={setInputBrandName}
                            options={
                                awardBrandNames[`${indexName}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <SelectInput
                            id="categories.lvl2MainAwards"
                            inputName={inputMainAward}
                            setInputName={setInputMainAward}
                            options={
                                mainAwards[`${inputBrandName}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <SelectInput
                            id="categories.lvl2MainCategories"
                            inputName={inputMainCategory}
                            setInputName={setInputMainCategory}
                            options={
                                mainCategories[`${inputMainAward}`].map(temp => ({ value: temp, name: temp }))
                            }
                            index={index}
                        />
                        <button
                            type="button"
                            className=" ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                                let temp = filterInputs[index]
                                let filterName = 'categories.lvl2'
                                let inputName = `${inputBrandName.trim()} > ${inputMainAward.trim()} > ${inputMainCategory.trim()}`
                                if (!([filterName] in temp)) {
                                    temp[filterName] = []
                                    temp[filterName].push(inputName);
                                } else if (!(temp[filterName].includes(inputName))) {
                                    temp[filterName].push(inputName);
                                }
                                setFilterInputs({ ...filterInputs, [`${index}`]: temp })
                            }}
                        >
                            Add +
                        </button>
                    </>
                }
            </>
            : <Loader height="h-4" width="w-4" />
    )
}
