import { useEffect, useState } from 'react'
import { SearchHits } from '../components/algolia/Search'
import {
    InstantSearch,
    Configure
} from 'react-instantsearch';
// import client from '../config/algolia';
import { CustomSeachBox } from '../components/algolia/CustomSeachBox';
import { db } from '../config/firebase';
import { awardStatus } from '../utils/constants';

const compare = (inputArr, inputObject) => {
    for (let index = 0; index < inputArr.length; index++) {
        const element = inputArr[index];
        if (Object.keys(element).length === Object.keys(inputObject).length) {
            for (let indexInner = 0; indexInner < Object.keys(element).length; indexInner++) {
                const elementInner = Object.keys(element)[indexInner];
                if (element[elementInner] !== inputObject[elementInner]) {
                    break;
                }
                if (indexInner === Object.keys(element).length - 1) {
                    return true
                }
            }
        }
    }
    return false;
}

const checkInputs = inputs => {
    let flag = false;
    for (let index = 0; index < Object.keys(inputs).length; index++) {
        const key = Object.keys(inputs)[index];
        if (inputs[key].length) {
            flag = true;
        }
    }
    return flag
}

const awardIndices = [
    'Movie',
    'TV'
]

const indexDecodes = {
    Person: 'persons',
    Company: 'companies',
    Network: 'networks'
}

const winnerIndices = {
    Movie: [
        'Person',
        'Company'
    ],
    TV: [
        'Person',
        'Company',
        'Network'
    ]
}

const statusDecode = {
    won: 1,
    nominated: 0
}

let awardIds = {};

function AwardsMatch() {
    const [error, setError] = useState({ status: false, message: '' });
    const [cmsId, setCmsId] = useState(1);
    const [indexType, setIndexType] = useState(awardIndices[0])
    const [inputIndex, setInputIndex] = useState(winnerIndices[`Movie`][0]);
    const [search, setSearch] = useState(false)
    const [inputSearch, setInputSearch] = useState(false)
    const [inputHit, setInputHit] = useState({});
    const [input2Hit, setInput2Hit] = useState({});
    const [awardsInputs, setAwardsInputs] = useState({});
    const [inputAwardYear, setInputAwardYear] = useState(2019);
    const [inputRanked, setInputRanked] = useState('1st');
    const [inputAwardStatus, setInputAwardStatus] = useState(0);
    const [inputBrandName, setInputBrandName] = useState('');
    const [brandNames, setBrandNames] = useState({});
    const [mainAwards, setMainAwards] = useState({});
    const [mainCategories, setMainCategories] = useState({});
    const [subCat1, setSubCat1] = useState({});
    const [subCat2, setSubCat2] = useState({});
    const [subCat3, setSubCat3] = useState({});
    const [inputMainAward, setInputMainAward] = useState('');
    const [inputMainCategory, setInputMainCategory] = useState('');
    const [inputSubCat1, setInputSubCat1] = useState('');
    const [inputSubCat2, setInputSubCat2] = useState('');
    const [inputSubCat3, setInputSubCat3] = useState('');
    const [toggleSubCat1, setToggleSubCat1] = useState(false);
    const [toggleSubCat2, setToggleSubCat2] = useState(false);
    const [toggleSubCat3, setToggleSubCat3] = useState(false);

    useEffect(() => {
        (async () => {
            let docs = (await db.collection("Awards").where("awardType", "array-contains", "FLM10").get()).docs
            let tempBrandNames = {};
            let tempMainAwards = {};
            let tempMainCategories = {};
            let tempSubCat1 = {};
            let tempSubCat2 = {};
            let tempSubCat3 = {};
            tempBrandNames.Movie = docs.map(doc => {
                let docData = doc.data()
                awardIds[`${docData.awardBrandName}`] = doc.id;
                tempMainAwards[`${docData.awardBrandName}`] = docData.mainAwards;
                docData.mainAwards.forEach(mainAward => {
                    tempMainCategories[`${mainAward}`] = docData.awards[`${mainAward}`]
                })
                docData.mainCatArray.forEach(tempCat => {
                    tempSubCat1[`${tempCat}`] = docData.mainCat[`${tempCat}`]
                })
                docData.subCat1Array.forEach(tempCat => {
                    tempSubCat2[`${tempCat}`] = docData.subCat1[`${tempCat}`]
                })
                docData.subCat2Array.forEach(tempCat => {
                    tempSubCat3[`${tempCat}`] = docData.subCat2[`${tempCat}`]
                })
                return docData.awardBrandName
            })
            docs = (await db.collection("Awards").where("awardType", "array-contains", "TV10").get()).docs
            tempBrandNames.All = tempBrandNames.Movie
            tempBrandNames.TV = docs.map(doc => {
                let docData = doc.data()
                awardIds[`${docData.awardBrandName}`] = doc.id;
                if (!(tempBrandNames.All.includes(docData.awardBrandName))) {
                    tempBrandNames.All.push(docData.awardBrandName);
                }
                tempMainAwards[`${docData.awardBrandName}`] = docData.mainAwards;
                docData.mainAwards.forEach(mainAward => {
                    tempMainCategories[`${mainAward}`] = docData.awards[`${mainAward}`]
                })
                docData.mainCatArray.forEach(tempCat => {
                    tempSubCat1[`${tempCat}`] = docData.mainCat[`${tempCat}`]
                })
                docData.subCat1Array.forEach(tempCat => {
                    tempSubCat2[`${tempCat}`] = docData.subCat1[`${tempCat}`]
                })
                docData.subCat2Array.forEach(tempCat => {
                    tempSubCat3[`${tempCat}`] = docData.subCat2[`${tempCat}`]
                })
                return docData.awardBrandName
            })
            setBrandNames(tempBrandNames)
            setMainAwards(tempMainAwards)
            setMainCategories(tempMainCategories)
            setSubCat1(tempSubCat1)
            setSubCat2(tempSubCat2)
            setSubCat3(tempSubCat3)
        })()
    }, [])

    useEffect(() => {
        setAwardsInputs({})
        setError({status: false, message: ''})
    }, [inputHit])

    useEffect(() => {
        if (error.status) {
            if (Object.keys(awardsInputs).length && checkInputs(awardsInputs)) {
                setError({status: false, message: ''})
            }
        }
    }, [error, awardsInputs])

    useEffect(() => {
        if (brandNames[`${indexType}`])
            setInputBrandName(brandNames[`${indexType}`][0])
    }, [brandNames, indexType])

    useEffect(() => {
        if (mainAwards[`${inputBrandName}`])
            setInputMainAward(mainAwards[`${inputBrandName}`][0])
    }, [inputBrandName, mainAwards]);

    useEffect(() => {
        if (mainCategories[`${inputMainAward}`])
            setInputMainCategory(mainCategories[`${inputMainAward}`][0])
    }, [inputMainAward, mainCategories]);

    useEffect(() => {
        if (subCat1[`${inputMainCategory}`])
            setInputSubCat1(subCat1[`${inputMainCategory}`][0])
    }, [inputMainCategory, subCat1]);

    useEffect(() => {
        if (subCat2[`${inputSubCat1}`])
            setInputSubCat2(subCat2[`${inputSubCat1}`][0])
    }, [inputSubCat1, subCat2]);

    useEffect(() => {
        if (subCat3[`${inputSubCat2}`])
            setInputSubCat3(subCat3[`${inputSubCat2}`][0])
    }, [inputSubCat2, subCat3]);

    return (
        <>
            <h2 className="font-extrabold text-center text-xl">Awarded {indexType === 'Movie' ? 'Movie' : 'TV show'}</h2>
            <div className="flex justify-center mt-6">
                <div className="flex justify-center w-2/3">
                    {
                        awardIndices.map(index => (
                            <button
                                key={index}
                                type="button"
                                className={`mx-5 inline-flex items-center px-5 py-2 border-2 text-base font-medium rounded-full shadow-sm focus:outline-none w-28 ${indexType === index ? 'bg-indigo-700 border-indigo-700 text-white' : 'bg-white text-black border-indigo-500 hover:bg-indigo-500 hover:text-white hover:border-transparent'}`}
                                onClick={() => {
                                    setInputHit({})
                                    setSearch(true)
                                    return index !== indexType ? setIndexType(index) : null
                                }}
                            >
                                <span className="text-center w-full">{index}</span>
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className="ais-InstantSearch">
                {/* <InstantSearch indexName={`${indexType}_awards_desc`} searchClient={client}>
                    <div>
                        <div className="flex justify-center my-4">
                            <div className="w-1/3">
                                <CustomSeachBox indexName={indexType} setSearch={setSearch} />
                            </div>
                        </div>
                        <Configure hitsPerPage={8} attributesToRetrieve={['title', 'name', 'poster_path', 'profile_path', 'logo_path']} />
                        {search && <SearchHits indexName={indexType} setHit={setInputHit} setSearch={setSearch} />}
                    </div>
                </InstantSearch> */}
            </div>
            {
                (!search && Object.keys(inputHit).length) ?
                    <div className="flex flex-col justify-center align-center mt-4">
                        <img className="w-32 mx-auto" src={inputHit.image} align="left" alt={inputHit.name} />
                        <div className="inputHit-price text-center m-2">{inputHit.name}</div>
                    </div> :
                    null
            }
            <h2 className="font-extrabold text-center text-xl">Award Shareholders</h2>
            <div className="flex justify-center mt-6">
                <div className="flex justify-center w-2/3">
                    {
                        winnerIndices[`${indexType}`].map(index => (
                            <button
                                key={index}
                                type="button"
                                className={`mx-5 inline-flex items-center px-5 py-2 border-2 text-base font-medium rounded-full shadow-sm focus:outline-none w-28 ${indexType === index ? 'bg-indigo-700 border-indigo-700 text-white' : 'bg-white text-black border-indigo-500 hover:bg-indigo-500 hover:text-white hover:border-transparent'}`}
                                onClick={() => {
                                    setInput2Hit({})
                                    setInputSearch(true)
                                    return index !== inputIndex ? setInputIndex(index) : null
                                }}
                            >
                                <span className="text-center w-full">{index}</span>
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className="ais-InstantSearch">
                {/* <InstantSearch indexName={`${inputIndex}_awards_desc`} searchClient={client}>
                    <div>
                        <div className="flex justify-center my-4">
                            <div className="w-1/3">
                                <CustomSeachBox indexName={inputIndex} setSearch={setInputSearch} />
                            </div>
                        </div>
                        <Configure hitsPerPage={8} attributesToRetrieve={['title', 'name', 'poster_path', 'profile_path', 'logo_path']} />
                        {inputSearch && <SearchHits indexName={inputIndex} setHit={setInput2Hit} setSearch={setInputSearch} />}
                    </div>
                </InstantSearch> */}
            </div>
            {
                (!inputSearch && Object.keys(input2Hit).length) ?
                    <>
                        <div className="flex flex-col justify-center align-center mt-4">
                            <img className="w-32 mx-auto" src={input2Hit.image} align="left" alt={input2Hit.name} />
                            <div className="inputHit-price text-center m-2">{input2Hit.name}</div>
                            <div className="w-24 mx-auto">
                                <button
                                    type="button"
                                    className=" ml-2 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => {
                                        let newInputs = awardsInputs;
                                        if (!([`${inputIndex}`] in newInputs)) {
                                            newInputs[`${inputIndex}`] = []
                                            newInputs[`${inputIndex}`].push(input2Hit);
                                        } else if (!(compare(newInputs[`${inputIndex}`], input2Hit))) {
                                            newInputs[`${inputIndex}`].push(input2Hit);
                                        }
                                        setInput2Hit({});
                                        setAwardsInputs({ ...newInputs })
                                    }}
                                >
                                    Add +
                                </button>
                            </div>
                        </div>
                    </> :
                    null
            }
            {
                Object.keys(awardsInputs).map(key => {
                    if (awardsInputs[key].length) {
                        return (
                            <div key={key}>
                                <div>
                                    <h5>{key}</h5>
                                </div>
                                <div key={awardsInputs[key][0]} className="flex items-start border-dashed border-4 border-blue-500 p-1 mt-2">
                                    {
                                        awardsInputs[key].map(tempItem => {
                                            return (
                                                <button
                                                    key={tempItem.objectID}
                                                    type="button"
                                                    className="mr-2 h-40 px-3 inline-flex items-center py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-600 hover:opacity-40 hover:line-through focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                                    onClick={() => {
                                                        let temp = awardsInputs
                                                        temp[key] = temp[key].filter(tempValue => tempValue.objectID !== tempItem.objectID)
                                                        setAwardsInputs({ ...temp });
                                                    }}
                                                >
                                                    <div className="flex flex-col justify-center items-center mt-4 w-18">
                                                        <img className="w-16 mx-auto" src={tempItem.image} align="left" alt={tempItem.name} />
                                                        <div className="inputHit-price text-center h-8">{tempItem.name}</div>
                                                    </div>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    else
                        return null
                })
            }
            <div className="flex flex-row justify-center my-8">
                <div className="mx-6">
                    <h2 className="font-bold">Award Year</h2>
                    <div>
                        <input
                            required
                            type="number"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            value={inputAwardYear}
                            onChange={e => setInputAwardYear(e.target.valueAsNumber)}
                        />
                    </div>
                </div>
                <div className="mx-6">
                    <h2 className="font-bold">Award Ranked</h2>
                    <div>
                        <input
                            required
                            type="text"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            value={inputRanked}
                            onChange={e => setInputRanked(e.target.value.trim())}
                        />
                    </div>
                </div>
                <div className="mx-6">
                    <h2 className="font-bold">Award Status</h2>
                    <div>
                        <label htmlFor="index" className="sr-only">
                            Award Status
                        </label>
                        <select
                            required
                            value={inputAwardStatus}
                            onChange={e => setInputAwardStatus(e.target.value)}
                            id="index"
                            className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        >
                            {
                                awardStatus.map(status => <option key={status} value={status}>{status}</option>)
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-center my-8">
                <div className="mx-6">
                    <h2 className="font-bold">Brand Name</h2>
                    <div>
                        <label htmlFor="index" className="sr-only">
                            Brand Name
                        </label>
                        <select
                            required
                            value={inputBrandName}
                            onChange={e => setInputBrandName(e.target.value)}
                            id="index"
                            className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        >
                            {
                                Object.keys(brandNames).length ?
                                    brandNames[`${indexType}`].map(brand => <option key={brand} value={brand}>{brand}</option>) :
                                    <></>
                            }
                        </select>
                    </div>
                </div>
                <div className="mx-6">
                    <h2 className="font-bold">Main Award</h2>
                    <div>
                        <label htmlFor="index" className="sr-only">
                            Main Award
                        </label>
                        <select
                            required
                            value={inputMainAward}
                            onChange={e => setInputMainAward(e.target.value)}
                            id="index"
                            className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        >
                            {
                                mainAwards[`${inputBrandName}`] && mainAwards[`${inputBrandName}`].length ?
                                    mainAwards[`${inputBrandName}`].map(award => <option key={award} value={award}>{award}</option>) :
                                    <></>
                            }
                        </select>
                    </div>
                </div>
                <div className="mx-6">
                    <h2 className="font-bold">Main Category</h2>
                    <div>
                        <label htmlFor="index" className="sr-only">
                            Main Category
                        </label>
                        <select
                            required
                            value={inputMainCategory}
                            onChange={e => setInputMainCategory(e.target.value)}
                            id="index"
                            className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        >
                            {
                                mainCategories[`${inputMainAward}`] && mainCategories[`${inputMainAward}`].length ?
                                    mainCategories[`${inputMainAward}`].map(tempCat => <option key={tempCat} value={tempCat}>{tempCat}</option>) :
                                    <></>
                            }
                        </select>
                    </div>
                </div>
                {
                    !toggleSubCat1 && subCat1[`${inputMainCategory}`] && subCat1[`${inputMainCategory}`].length ?
                        <div className="mx-4 mt-4">
                            <button
                                type="button"
                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setToggleSubCat1(true)}
                            >
                                Add Sub Category 1
                            </button>
                        </div> :
                        <></>
                }
                {
                    toggleSubCat1 && subCat1[`${inputMainCategory}`] && subCat1[`${inputMainCategory}`].length ?
                        <div className="mx-6">
                            <h2 className="font-bold">Sub Category 1</h2>
                            <div className="flex flex-row">
                                <label htmlFor="index" className="sr-only">
                                    Sub Category 1
                                </label>
                                <select
                                    required
                                    value={inputSubCat1}
                                    onChange={e => setInputSubCat1(e.target.value)}
                                    id="index"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                >
                                    {
                                        subCat1[`${inputMainCategory}`].map(tempCat => <option key={tempCat} value={tempCat}>{tempCat}</option>)
                                    }
                                </select>
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => {
                                        setToggleSubCat3(false)
                                        setToggleSubCat2(false)
                                        setToggleSubCat1(false)
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div> :
                        <></>
                }
                {
                    !toggleSubCat2 && toggleSubCat1 && subCat2[`${inputSubCat1}`] && subCat2[`${inputSubCat1}`].length ?
                        <div className="mx-4 mt-4">
                            <button
                                type="button"
                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setToggleSubCat2(true)}
                            >
                                Add Sub Category 2
                            </button>
                        </div> :
                        <></>
                }
                {
                    toggleSubCat2 && subCat2[`${inputSubCat1}`] && subCat2[`${inputSubCat1}`].length ?
                        <div className="mx-6">
                            <h2 className="font-bold">Sub Category 2</h2>
                            <div className="flex flex-row">
                                <label htmlFor="index" className="sr-only">
                                    Sub Category 2
                                </label>
                                <select
                                    required
                                    value={inputSubCat2}
                                    onChange={e => setInputSubCat2(e.target.value)}
                                    id="index"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                >
                                    {
                                        subCat2[`${inputSubCat1}`].map(tempCat => <option key={tempCat} value={tempCat}>{tempCat}</option>)
                                    }
                                </select>
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => {
                                        setToggleSubCat3(false)
                                        setToggleSubCat2(false)
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div> :
                        <></>
                }
                {
                    !toggleSubCat3 && toggleSubCat2 && subCat3[`${inputSubCat2}`] && subCat3[`${inputSubCat2}`].length ?
                        <div className="mx-4 mt-4">
                            <button
                                type="button"
                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setToggleSubCat3(true)}
                            >
                                Add Sub Category 3
                            </button>
                        </div> :
                        <></>
                }
                {
                    toggleSubCat3 && subCat3[`${inputSubCat2}`] && subCat3[`${inputSubCat2}`].length ?
                        <div className="mx-6">
                            <h2 className="font-bold">Sub Category 3</h2>
                            <div className="flex flex-row">
                                <label htmlFor="index" className="sr-only">
                                    Sub Category 3
                                </label>
                                <select
                                    required
                                    value={inputSubCat3}
                                    onChange={e => setInputSubCat3(e.target.value)}
                                    id="index"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                >
                                    {
                                        subCat3[`${inputSubCat2}`].map(tempCat => <option key={tempCat} value={tempCat}>{tempCat}</option>)
                                    }
                                </select>
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setToggleSubCat3(false)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div> :
                        <></>
                }
            </div>
            <div className={`w-96 mx-auto rounded-md bg-red-100 p-4 ${error.status ? 'justify-center flex mb-4' : 'hidden'}`}>
                <div className="flex flex-row">
                    <div>
                        <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <div className="text-sm text-yellow-700">
                            <p>
                                {error.message}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="w-64 mx-auto mb-8">
                    <button
                        type="button"
                        className="w-full inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={async () => {
                            if (Object.keys(inputHit).length && Object.keys(awardsInputs).length) {
                                if (checkInputs(awardsInputs)) {
                                    let savingAward = {
                                        ranked: inputRanked,
                                        'W/N': parseInt(statusDecode[`${inputAwardStatus}`]),
                                        brandName: inputBrandName,
                                        mainAward: inputMainAward,
                                        mainCategory: inputMainCategory,
                                        awardId: parseInt(awardIds[`${inputBrandName}`]),
                                        awardYear: inputAwardYear
                                    }
                                    // console.log(`Input hit :: ${JSON.stringify(inputHit, null, 2)}`);
                                    if (indexType === 'Movie') {
                                        savingAward.awardType = 'FLM10'
                                        savingAward.filmId = parseInt(inputHit.objectID)
                                    } else if (indexType === 'TV') {
                                        savingAward.awardType = 'TV10'
                                        savingAward.tvId = parseInt(inputHit.objectID)
                                    }
                                    savingAward.name = inputHit.name
                                    winnerIndices[`${indexType}`].forEach(index => {
                                        if (([`${index}`] in awardsInputs) && awardsInputs[`${index}`].length) {
                                            savingAward[`${indexDecodes[`${index}`]}`] = awardsInputs[`${index}`].map(temp => parseInt(temp.objectID))
                                        }
                                    })
                                    if (toggleSubCat1) {
                                        savingAward.subCategory1 = inputSubCat1
                                    }
                                    if (toggleSubCat2) {
                                        savingAward.subCategory2 = inputSubCat2
                                    }
                                    if (toggleSubCat3) {
                                        savingAward.subCategory3 = inputSubCat3
                                    }
                                    await db.collection('AwardsMatching').doc(`CMS${cmsId}`).set(savingAward);
                                    // console.log(`Saving Award :: ${JSON.stringify(savingAward, null, 4)}`);
                                    setCmsId(cmsId + 1);
                                } else {
                                    setError({
                                        message: 'Kindly select persons, companies or networks to store awards',
                                        status: true
                                    })
                                }
                            } else if (!(Object.keys(inputHit).length)) {
                                setError({
                                    message: 'Kindly select a movie or TV show to store award',
                                    status: true
                                })
                            } else if (!(Object.keys(awardsInputs).length)) {
                                setError({
                                    message: 'Kindly select persons, companies or networks to store awards',
                                    status: true
                                })
                            }
                        }}
                    >
                        <p className="text-center w-full">ADD AWARD</p>
                    </button>
                </div>
            </div>
        </>
    )
}

export default AwardsMatch
