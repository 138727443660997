import React from "react";
import { Pre, Line, LineNo, LineContent } from "./styles";
import {Highlight, themes} from "prism-react-renderer";

const CodeBlock = ({ language, codeString, highlightedLines }) => {
    return (
        <Highlight theme={themes.nightOwl} code={codeString} language={language}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Pre className={className} style={style}>
                    {tokens.map((line, index) => {
                        const lineProps = getLineProps({ line, key: index })
                        if (highlightedLines.includes(index + 1)) { lineProps.className = `${lineProps.className} highlight-line` }
                        return (
                            <Line key={index} {...lineProps}>
                                <LineNo>{index + 1}</LineNo>
                                <LineContent>
                                    {line.map((token, key) => (
                                        <span key={key} {...getTokenProps({ token, key })} />
                                    ))}
                                </LineContent>
                            </Line>
                        )
                    })}
                </Pre>
            )}
        </Highlight>
    );
};

export default CodeBlock;