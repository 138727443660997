import React, { createContext, useState, useEffect } from 'react'
import { db } from '../../config/firebase'

export const dataContext = createContext();

export default function DataContextProvider(props) {
    const [genres, setGenres] = useState({});
    const [mainAwards, setMainAwards] = useState({});
    const [mainCategories, setMainCategories] = useState({});
    const [awardBrandNames, setAwardBrandNames] = useState({});
    const [departments, setDepartments] = useState([]);
    const [countries, setCountries] = useState([]);
    const [awardYears, setAwardYears] = useState([]);
    useEffect(() => {
        (async () => {
            let tempBrandNames = {};
            let tempCategories = {};
            let tempMainAwards = {};
            let tempGenres = {};
            let data = (await db.collection('Genre').doc('Movie').get()).data()
            tempGenres.Movie = data.genres.map(genre => genre.name);
            data = (await db.collection('Genre').doc('TV').get()).data()
            tempGenres.All = tempGenres.Movie
            tempGenres.TV = data.genres.map(genre => {
                let temp = genre.name;
                if (!(tempGenres.All.includes(temp))) {
                    tempGenres.All.push(temp)
                }
                return temp
            });
            tempGenres.Company = tempGenres.All;
            tempGenres.Network = tempGenres.TV
            tempGenres.Person = tempGenres.All
            setGenres(tempGenres);
            let docs = (await db.collection("Awards").where("awardType", "array-contains", "FLM10").get()).docs
            tempBrandNames.Movie = docs.map(doc => {
                let docData = doc.data()
                tempMainAwards[`${docData.awardBrandName.trim()}`] = docData.mainAwards;
                docData.mainAwards.forEach(mainAward => {
                    tempCategories[`${mainAward.trim()}`] = docData.awards[`${mainAward.trim()}`]
                })
                return docData.awardBrandName.trim()
            })
            docs = (await db.collection("Awards").where("awardType", "array-contains", "TV10").get()).docs
            tempBrandNames.All = tempBrandNames.Movie
            tempBrandNames.TV = docs.map(doc => {
                let docData = doc.data()
                if (!(tempBrandNames.All.includes(docData.awardBrandName.trim()))) {
                    tempBrandNames.All.push(docData.awardBrandName.trim());
                }
                tempMainAwards[`${docData.awardBrandName.trim()}`] = docData.mainAwards;
                docData.mainAwards.forEach(mainAward => {
                    tempCategories[`${mainAward.trim()}`] = docData.awards[`${mainAward.trim()}`]
                })
                return docData.awardBrandName.trim()
            })
            tempBrandNames.Company = tempBrandNames.All;
            tempBrandNames.Network = tempBrandNames.TV
            tempBrandNames.Person = tempBrandNames.All
            setAwardBrandNames(tempBrandNames)
            setMainAwards(tempMainAwards)
            setMainCategories(tempCategories)
            data = (await db.collection('Configuration').doc('Countries').get()).data()
            let tempCountries = data.countries.map(country => ({ name: country.english_name, code: country.iso_3166_1 }))
            setCountries(tempCountries)
            data = (await db.collection('Configuration').doc('Jobs').get()).data()
            let tempDepartments = data.jobs.map(job => job.department)
            setDepartments(tempDepartments)
            data = (await db.collection('Awards').doc('Academy-Awards').get()).data();
            setAwardYears(data.awardYears)
        })()
    }, [])
    return (
        <dataContext.Provider value={{
            awardBrandNames,
            mainAwards,
            mainCategories,
            awardYears,
            departments,
            countries,
            genres
        }}>
            {props.children}
        </dataContext.Provider>
    )
}
