/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { Bars3Icon as MenuIcon, XMarkIcon as XIcon } from '@heroicons/react/24/outline'
import { auth } from '../config/firebase'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
    const location = useLocation()
    const [user, setUser] = useState(null)
    const [navigation, setNavigation] = useState([
        { name: 'Dashboard', href: '/', current: location.pathname === '/' ? true : false },
        { name: 'Carousels', href: '/carousels', current: location.pathname === '/carousels' ? true : false },
        { name: 'Users', href: '/users', current: location.pathname === '/users' ? true : false },
        { name: 'Related Posts', href: '/posts', current: location.pathname === '/posts' ? true : false },
        { name: 'Awards Match', href: '/awardsmatch', current: location.pathname === '/awardsmatch' ? true : false },
        { name: 'Blog Posts', href: '/blogposts', current: location.pathname === '/blogposts' ? true : false }
    ])
    useEffect(() => {
        if (!user) {
            setUser(auth.currentUser)
        }
    }, [user])
    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-2">
                        <div className="flex justify-between h-14">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-8 w-auto"
                                        src="/assets/img/Awards_Box_Logo.svg"
                                        alt="Workflow"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src="/assets/img/Awards_Box_Logo.svg"
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    {navigation.map((item, index) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            aria-current={item.current ? 'page' : undefined}
                                            className={classNames(
                                                item.current ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium'
                                            )}
                                            onClick={() => {
                                                let newNav = navigation;
                                                newNav = newNav.map(navItem => {
                                                    if (navItem.current) {
                                                        navItem.current = false;
                                                    }
                                                    return navItem;
                                                })
                                                newNav[index].current = true;
                                                setNavigation(newNav);
                                            }}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:items-center">

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    {({ open }) => (
                                        <>
                                            <div>
                                                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    <span className="sr-only">Open user menu</span>
                                                    <img
                                                        referrerPolicy='no-referrer'
                                                        className="h-10 w-10 rounded-full"
                                                        src={user ? user.photoURL : ''}
                                                        alt=""
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                >
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="/"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                                onClick={async () => {
                                                                    await auth.signOut();
                                                                }}
                                                            >
                                                                Sign out
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    aria-current={item.current ? 'page' : undefined}
                                    className={classNames(
                                        item.current ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                    )}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="h-10 w-10 rounded-full"
                                        src={user ? user.photoURL : ''}
                                        alt=""
                                    />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">{user ? user.displayName : ''}</div>
                                    <div className="text-sm font-medium text-gray-500">{user ? user.email : ''}</div>
                                </div>
                            </div>
                            <div className="mt-3 space-y-1">
                                <a
                                    href="/"
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    onClick={async () => {
                                        await auth.signOut();
                                    }}
                                >
                                    Sign out
                                </a>
                            </div>
                        </div>
                    </Disclosure.Panel>
                    <div className="border-gray-500 bg-gray-500 h-1 mt-3"></div>
                </>
            )
            }
        </Disclosure >
    )
}
