import React from 'react'

export default function AddButton({ filterInputs, setFilterInputs, filterName, inputName, index }) {
    return (
        <button
            type="button"
            className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
                let temp = filterInputs[index]
                //     if (index === idx) {
                if (!([filterName] in temp)) {
                    temp[filterName] = []
                    temp[filterName].push(inputName);
                } else if (!(temp[filterName].includes(inputName))) {
                    temp[filterName].push(inputName);
                }
                //     }
                //     return temp
                setFilterInputs({ ...filterInputs, [`${index}`]: temp })
            }}
        >
            Add +
        </button>
    )
}
