import React from 'react'

export default function SelectInput({id, inputName, setInputName, options}) {
    return (
        <div>
            <label htmlFor={id} className="sr-only">
                filterType
            </label>
            <select
                required
                value={inputName}
                onChange={e => setInputName(e.target.value)}
                id={id}
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            >
                {
                    options.map(temp => <option key={temp.value} value={temp.value}>{temp.name}</option>)
                }
            </select>
        </div>
    )
}
