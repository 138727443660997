import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import SinglePost from './Blogs/SinglePost'
import Navbar from './Navbar'
import AwardsMatch from '../pages/AwardsMatch'
import BlogPosts from '../pages/BlogPosts'
import Carousels from '../pages/Carousels'
import Home from '../pages/Home'
import Posts from '../pages/Posts'
import Users from '../pages/Users'

function Dashboard() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/carousels" element={<Carousels />} />
                <Route exact path="/users" element={<Users />} />
                <Route exact path="/posts" element={<Posts />} />
                <Route exact path="/awardsmatch" element={<AwardsMatch />} />
                <Route exact path="/blogposts" element={<BlogPosts />} />
                <Route path="/blogposts/:link" element={<SinglePost />} />
            </Routes>
        </Router>
    )
}

export default Dashboard
