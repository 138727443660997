import SignIn from "./components/SignIn";
import { auth } from './config/firebase';
import { useState, useEffect } from "react";
import Dashboard from "./components/Dashboard";
import Loader from "./utils/Loader";

function App() {
  const [error, setError] = useState({
    status: false,
    message: null
  });

  const [showLoader, setShowLoader] = useState(true);
  const [user, setUser] = useState(auth.currentUser);

  auth.onAuthStateChanged(user => {
    setUser(user);
  })

  useEffect(() => {
    setTimeout(() => setShowLoader(false), 1000)
  }, [])

  useEffect(() => {
    if (user !== null) {
      (async () => {
        let idToken = await user.getIdTokenResult();
        if (!idToken.claims.admin) {
          await auth.signOut();
          setError({
            message: 'Kindly sign in with admin account!!',
            status: true
          })
        }
      })()
    }
    return;
  }, [user])

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://cdn.embedly.com/widgets/platform.js";
    script.async = true;
    script.charset = "UTF-8"

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      {
        showLoader
          ? <div className="relative -bottom-8">
            <Loader height="h-20" width="w-20" />
          </div>
          : user !== null
            ? <Dashboard />
            : <SignIn error={error} />
      }
    </>
  );
}

export default App;
