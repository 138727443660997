import React from 'react'
import SelectFilterInput from './SelectFilterInput'

const styles = {
    "landscape": [1, 2, 3, 4],
    "portrait": [1, 2, 3, 4, 5]
}

const carouselTypes = {
    "Movie": ["portrait", "landscape"],
    "TV": ["portrait", "landscape"],
    "Person": ["portrait"],
    "Company": ["landscape"],
    "Network": ["landscape"],
}

export default function StyleInput({ edit, type, style, carouselStyle, setCarouselStyle, carouselType, setCarouselType, index, indexName }) {
    return (
        edit ?
            <>
                <fieldset className="mt-2 bg-white">
                    <legend className="block text-sm font-medium text-gray-700">Style Config</legend>
                    <div className="mt-1 rounded-md shadow-sm -space-y-px">
                        <SelectFilterInput
                            id="CarouselType"
                            inputName={carouselType}
                            setInputName={setCarouselType}
                            options={indexName[index] ? carouselTypes[`${indexName[index]}`].map(option => ({ value: option, name: option })) : []}
                            index={index}
                        />
                        <SelectFilterInput
                            id="CarouselStyle"
                            inputName={carouselStyle}
                            setInputName={setCarouselStyle}
                            options={
                                carouselType[index] ? styles[`${carouselType[index]}`].map(style => ({ value: style, name: style })) : []
                            }
                            index={index}
                        />
                    </div>
                </fieldset>
            </> :
            <>
                <p>
                    {`Type: ${type}`}
                </p>
                <p>
                    {`Style: ${style}`}
                </p>
            </>
    )
}
