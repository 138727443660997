import { useState, useEffect } from 'react'
import { indices } from '../utils/constants'
import { SearchHits } from '../components/algolia/Search'
import {
    InstantSearch,
    Configure
} from 'react-instantsearch';
// import client from '../config/algolia';
import { CustomSeachBox } from '../components/algolia/CustomSeachBox';
import { db } from '../config/firebase';


function Posts() {
    const [indexType, setIndexType] = useState(indices[0])
    const [category, setCategory] = useState('quora');
    const [search, setSearch] = useState(false)
    const [inputHit, setInputHit] = useState({});
    const [inputEmbed, setInputEmbed] = useState('')
    const [error, setError] = useState({
        status: false,
        message: ''
    })
    const [inputPreview, setInputPreview] = useState('')
    const [quoraPreview, setQuoraPreview] = useState('')
    const [redditPreview, setRedditPreview] = useState('')

    useEffect(() => {
        if (Object.keys(inputHit).length) {
            (async () => {
                let docData = (await db.collection(indexType).doc('Details').collection('ID').doc(`${inputHit.objectID}`).get()).data()
                setRedditPreview(docData.reddit ? docData.reddit : '')
                setQuoraPreview(docData.quora ? docData.quora : '')
            })()
        }
    }, [inputHit, indexType])

    useEffect(() => {
        setInputPreview(inputEmbed)
    }, [inputEmbed])

    useEffect(() => {
        document.getElementById('previewContainer').innerHTML = inputPreview
    }, [inputPreview])

    useEffect(() => {
        document.getElementById('quoraPreview').innerHTML = quoraPreview ? quoraPreview : ''
    }, [quoraPreview])

    useEffect(() => {
        document.getElementById('redditPreview').innerHTML = redditPreview ? redditPreview : ''
    }, [redditPreview])

    return (
        <>
            <div className="flex justify-center mt-6">
                <div className="flex justify-between w-2/3">
                    {
                        indices.map(index => (
                            <button
                                key={index}
                                type="button"
                                className={`inline-flex items-center px-5 py-2 border-2 text-base font-medium rounded-full shadow-sm focus:outline-none w-28 ${indexType === index ? 'bg-indigo-700 border-indigo-700 text-white' : 'bg-white text-black border-indigo-500 hover:bg-indigo-500 hover:text-white hover:border-transparent'}`}
                                onClick={() => {
                                    setInputHit({})
                                    setSearch(true)
                                    return index !== indexType ? setIndexType(index) : null
                                }}
                            >
                                <span className="text-center w-full">{index}</span>
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className="ais-InstantSearch">
                {/* <InstantSearch indexName={`${indexType}_awards_desc`} searchClient={client}>
                    <div>
                        <div className="flex justify-center my-4">
                            <div className="w-1/3">
                                <CustomSeachBox indexName={indexType} setSearch={setSearch} />
                            </div>
                        </div>
                        <Configure hitsPerPage={8} attributesToRetrieve={['title', 'name', 'poster_path', 'profile_path', 'logo_path']} />
                        {search && <SearchHits indexName={indexType} setHit={setInputHit} setSearch={setSearch} />}
                    </div>
                </InstantSearch> */}
            </div>
            {
                !search && inputHit !== null &&
                <div className="flex flex-col justify-center align-center mt-4">
                    <img className="w-32 mx-auto" src={inputHit.image} align="left" alt={inputHit.name} />
                    <div className="inputHit-price text-center m-2">{inputHit.name}</div>
                </div>
            }
            {
                <div className={`flex justify-center border-t-4 border-b-4 border-gray-400 pb-4 ${(quoraPreview || redditPreview) ? '' : 'hidden'}`}>
                    <div className="w-5/6 flex flex-row justify-center">
                        <div className={`mt-2${redditPreview ? 'w-1/2' : ''} ${quoraPreview ? '' : 'hidden'}`}>
                            <h3 className="text-center font-bold text-3xl">Quora</h3>
                            <div id="quoraPreview"></div>
                            <div className="flex justify-center">
                                <div className="w-24">
                                    <button
                                        type="button"
                                        className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                                        onClick={() => {
                                            (
                                                async () => {
                                                    await db.collection(indexType).doc('Details').collection('ID').doc(`${inputHit.objectID}`).set({
                                                        quora: ''
                                                    }, { merge: true });
                                                    setError({
                                                        status: false,
                                                        message: ''
                                                    })
                                                    setInputHit({ ...inputHit })
                                                    setQuoraPreview('')
                                                }
                                            )()
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`${quoraPreview ? 'w-1/2' : ''} ${redditPreview ? '' : 'hidden'}`}>
                            <h3 className="text-center font-bold text-3xl mb-8">Reddit</h3>
                            <div id="redditPreview"></div>
                            <div className="flex justify-center">
                                <div className="w-24">
                                    <button
                                        type="button"
                                        className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                                        onClick={() => {
                                            (
                                                async () => {
                                                    await db.collection(indexType).doc('Details').collection('ID').doc(`${inputHit.objectID}`).set({
                                                        reddit: ''
                                                    }, { merge: true });
                                                    setError({
                                                        status: false,
                                                        message: ''
                                                    })
                                                    setInputHit({ ...inputHit })
                                                    setRedditPreview('')
                                                }
                                            )()
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="flex justify-center mt-6">
                <div>
                    <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                        <h3 className="text-2xl">
                            Category
                        </h3>
                    </label>
                    <select
                        id="category"
                        name="category"
                        className="mt-1 block w-full pl-3 pr-10 py-2 border-2 border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 xl:text-lg lg:text-lg rounded-md"
                        value={category}
                        onChange={e => setCategory(e.target.value)}
                    >
                        <option value="quora" className="text-lg">Quora</option>
                        <option value="reddit" className="text-lg">Reddit</option>
                    </select>
                </div>
            </div>
            <div className="mt-8 flex justify-center">
                <div className="w-full flex flex-col justify-center">
                    <div className="w-1/2 mx-auto">
                        <label htmlFor="Embed" className="sr-only">
                            embed
                        </label>
                        <textarea
                            id="Embed"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-400 rounded-md"
                            placeholder='Paste the embed code'
                            value={inputEmbed}
                            onChange={e => setInputEmbed(e.target.value)}
                        >
                        </textarea>
                    </div>
                    <div className={`w-24 mx-auto mt-2 ${inputEmbed !== '' ? '' : 'hidden'}`}>
                        <button
                            type="button"
                            className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                                if (Object.keys(inputHit).length) {
                                    (
                                        async () => {
                                            let tempEmbed = inputEmbed.split('<script async')[0]
                                            await db.collection(indexType).doc('Details').collection('ID').doc(`${inputHit.objectID}`).set({
                                                [`${category}`]: tempEmbed
                                            }, { merge: true });
                                            setError({
                                                status: false,
                                                message: ''
                                            })
                                            setInputEmbed('')
                                            setInputHit({ ...inputHit })
                                            setQuoraPreview('');
                                            setRedditPreview('')
                                        }
                                    )()
                                } else {
                                    setError({
                                        status: true,
                                        message: 'Kindly select an instance of one of the 5 categories above!'
                                    })
                                }
                            }}
                        >
                            Add +
                        </button>
                    </div>
                    <div className={`mt-4 rounded-md bg-red-100 p-4 ${error.status ? 'justify-center flex' : 'hidden'}`}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-yellow-800">
                                    Attention needed
                                </h3>
                                <div className="mt-2 text-sm text-yellow-700">
                                    <p>
                                        {error.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="previewContainer" className={`mt-6 flex justify-center ${inputEmbed !== '' ? '' : "hidden"}`}></div>
        </>
    )
}

export default Posts
