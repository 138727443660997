import React, { useState } from 'react'

export default function SelectFilterInput({ id, value, inputName, setInputName, options, index }) {
    const [input, setInput] = useState(value);
    return (
        <div>
            <label htmlFor={id} className="sr-only">
                {id}
            </label>
            <select
                required
                value={input}
                onChange={e => {
                    setInputName({ ...inputName, [`${index}`]: e.target.value })
                    setInput(e.target.value)
                }}
                id={id}
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            >
                {
                    options.map(
                        optionName => <option
                            key={`${optionName.value}`}
                            value={optionName.value === true ? true : optionName.value === false ? false : optionName.value}
                        >
                            {optionName.name}
                        </option>
                    )
                }
            </select>
        </div>
    )
}
