let removeAllBrackets = field => `${field.replace(/[()']/g, '')}`

export const decodeFilters = filters => {
    let firstSplit = removeAllBrackets(filters.trim()).split('AND');
    let secondSplit = {}
    if (firstSplit[0] !== '') {
        for (const tempSplit of firstSplit) {
            secondSplit[`${tempSplit.split(':')[0].trim()}`] = tempSplit.trim().split('OR').map(mapSplit => mapSplit.split(':')[1].trim())
        }
    }
    return secondSplit;
}

export const getFilterStr = tempFilterInputs => {
    let filterStr = '';
    let checkFilter = false;
    Object.keys(tempFilterInputs).forEach((key, keyIdx) => {
        if (tempFilterInputs[key].length) {
            filterStr += (keyIdx !== 0 && checkFilter) ? ' AND ' : ''
            filterStr += '('
            tempFilterInputs[key].forEach((tempFilter, filterIdx) => {
                filterStr += `${key}:${(tempFilter.search(' ') !== -1 && tempFilter.search('TO') === -1) ? `'${tempFilter}'` : `${tempFilter}`}`
                filterStr += (filterIdx + 1) === tempFilterInputs[key].length ? '' : ' OR '
            })
            filterStr += ')'
            checkFilter = true;
        }
    })
    return filterStr;
}

export const indices = [
    'Movie',
    'TV',
    'Person',
    'Company',
    'Network'
]

export const sortMethods = {
    Movie: [
        'awards',
        'title',
        'release date'
    ],
    TV: [
        'awards',
        'title',
        'release date'
    ],
    Company: [
        'awards',
        'name'
    ],
    Network: [
        'awards',
        'name'
    ],
    Person: [
        'awards',
        'name'
    ],
    AwardDetails: []
}

export const filterTypes = {
    Movie: [
        'brand names',
        'main awards',
        'main categories',
        'award years',
        'award status',
        'genres',
        'labels'
    ],
    TV: [
        'brand names',
        'main awards',
        'main categories',
        'award years',
        'award status',
        'genres',
        'labels'
    ],
    Person: [
        'brand names',
        'main awards',
        'main categories',
        'award years',
        'award status',
        'department'
    ],
    Company: [
        'brand names',
        'main awards',
        'main categories',
        'award years',
        'award status',
        'country'
    ],
    Network: [
        'brand names',
        'main awards',
        'main categories',
        'award years',
        'award status',
        'country'
    ]
}

export const filterDecodes = {
    "genres": "genres.name",
    "award years": "awardYear",
    "award status": "status",
    "country": "origin_country",
    "department": "known_for_department",
    "brand names": "categories.lvl0",
    "main awards": "categories.lvl1",
    "main categories": "categories.lvl2",
    "labels": "labels"
}

export const filterEncodes = {
    "genres.name": "genres",
    "awardYear": "award years",
    "status": "award status",
    "origin_country": "country",
    "known_for_department": "department",
    "categories.lvl0": "brand names",
    "categories.lvl1": "main awards",
    "categories.lvl2": "main categories",
    "labels": "labels"
}

export const claimTypes = [
    'admin',
    'creator'
]

export const imagePaths = {
    Movie: 'poster_path',
    TV: 'poster_path',
    Person: 'profile_path',
    Network: 'logo_path',
    Company: 'logo_path'
}

export const names = {
    Movie: 'title',
    TV: 'name',
    Person: 'name',
    Network: 'name',
    Company: 'name'
}

export const awardStatus = [
    'nominated',
    'won'
]

export const labels = [
    "Featured",
    "LGBTQ+",
    "Green Film"
]