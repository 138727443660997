import React, { useState } from 'react'
import { indices, sortMethods } from '../../../../utils/constants'
import SelectFilterInput from './SelectFilterInput'

const carouselTypes = {
    "Person": "portrait",
    "Company": "landscape",
    "Network": "landscape",
}

export default function IndexNameInput({ edit, indexName, indexNameInput, setIndexNameInput, sortMethodsName, setSortMethodsName, sortDirection, setSortDirection, carouselType, setCarouselType, index }) {
    let options = indices.map(tempIndexName => ({ value: tempIndexName, name: tempIndexName }))
    const [input, setInput] = useState(indexName.split('_')[0]);

    return (
        edit ?
            <>
                <fieldset className="mt-2 bg-white">
                    <legend className="block text-sm font-medium text-gray-700">Index Config</legend>
                    <div className="mt-1 rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="indexName" className="sr-only">
                                indexName
                            </label>
                            <select
                                required
                                value={input}
                                onChange={e => {
                                    setIndexNameInput({ ...indexNameInput, [`${index}`]: e.target.value })
                                    setInput(e.target.value)
                                    if (e.target.value !== "Movie" && e.target.value !== "TV") {
                                        setCarouselType({ ...carouselType, [`${index}`]: carouselTypes[e.target.value] })
                                    }
                                }}
                                id="indexName"
                                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                            >
                                {
                                    options.map(
                                        optionName => <option
                                            key={`${optionName.value}`}
                                            value={optionName.value === true ? true : optionName.value === false ? false : optionName.value}
                                        >
                                            {optionName.name}
                                        </option>
                                    )
                                }
                            </select>
                        </div>
                        <SelectFilterInput
                            id="sortMethod"
                            value={indexName.split('_')[1]}
                            inputName={sortMethodsName}
                            setInputName={setSortMethodsName}
                            options={
                                (indexNameInput && indexNameInput[index])
                                    ? sortMethods[`${indexNameInput[index]}`].map(sort => ({ value: sort === 'release date' ? 'date' : sort, name: sort }))
                                    : ["no option"]
                            }
                            index={index}
                        />
                        <SelectFilterInput
                            id="sortDirection"
                            value={indexName.split('_')[2]}
                            inputName={sortDirection}
                            setInputName={setSortDirection}
                            options={[{ value: "asc", name: "Ascending" }, { value: "desc", name: "Descending" }]}
                            index={index}
                        />
                    </div>
                </fieldset>
            </> :
            indexName
    )
}
