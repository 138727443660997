import React from 'react'
import { filterEncodes } from '../../../../utils/constants'

export default function FilterDisplay({ filterInputs, setFilterInputs, index }) {
    return filterInputs && filterInputs[index] && Object.keys(filterInputs).length && Object.keys(filterInputs[index]).length ?
        Object.keys(filterInputs[index]).map(key => {
            if (filterInputs[index][key].length) {
                return <div key={`${index}${key}`}>
                    <div>
                        <h5>{filterEncodes[key]}</h5>
                    </div>
                    <div className="border-dashed border-4 border-blue-500 p-1 mt-2">
                        {
                            filterInputs[index][key].length
                                ? filterInputs[index][key].map(tempItem => {
                                    return <button
                                        key={`${index}${tempItem}`}
                                        type="button"
                                        className="mr-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 hover:line-through focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                        onClick={() => {
                                            let temp = filterInputs[index]
                                            temp[key] = temp[key].filter(tempValue => tempValue !== tempItem)
                                            setFilterInputs({ ...filterInputs, [`${index}`]: temp })
                                        }}
                                    >
                                        {tempItem}
                                    </button>
                                })
                                : <></>
                        }
                    </div>
                </div>
            }
            else
                return <div key={key} className="hidden"></div>
        }) :
        <></>
}
