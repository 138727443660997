import React from 'react'
// import client from '../config/algolia'
import { InstantSearch } from 'react-instantsearch';
import { CustomHits } from '../components/algolia/CustomHits';
import DataContextProvider from '../components/algolia/DataContextProvider';
import { CustomPagination } from '../components/algolia/CustomPagination';
import { Configure } from 'react-instantsearch';

function Carousels() {
    return (
        <InstantSearch
            indexName="carousels_config"
        >
            <Configure hitsPerPage={50}/>
            <DataContextProvider>
                <CustomHits />
            </DataContextProvider>
            <CustomPagination />
        </InstantSearch>
    )
}

export default Carousels
