import React, { useState } from 'react'

export default function OrderInput({ edit, order, orderInput, setOrderInput, index }) {
    const [input, setInput] = useState(order);
    return (
        edit ?
            <>
                <div className="mt-1">
                    <input
                        required
                        type="number"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={input}
                        onChange={e => {
                            setOrderInput({ ...orderInput, index: parseInt(e.target.value) })
                            setInput(e.target.value)
                        }}
                    />
                </div>
            </> :
            order
    )
}
