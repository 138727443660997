import { usePagination } from "react-instantsearch";

export const CustomPagination = (props) => {
    const { currentRefinement, nbPages, refine, createURL } = usePagination(props)
    return (
        < div className="flex justify-center my-4" >
            {
                new Array(nbPages).fill(null).map((_, index) => {
                    const page = index + 1;
                    const style = {
                        fontWeight: currentRefinement === page ? 'bold' : '',
                    };

                    return (
                        <div key={index} className="mx-3 px-2 rounded-md bg-gray-500">
                            <a
                                className="text-xl"
                                href={createURL(page)}
                                style={style}
                                onClick={event => {
                                    event.preventDefault();
                                    refine(page);
                                }}
                            >
                                {page}
                            </a>
                        </div>
                    );
                })
            }
        </div >
    )
}

// 2. Connect the component using the connector
// connectPagination(Pagination);