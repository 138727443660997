import React, { useState } from 'react'
import { filterDecodes, filterTypes } from '../../../../utils/constants';
import Loader from '../../../../utils/Loader'
import FilterSelect from './FilterSelect';
import FilterDisplay from './FilterDisplay';
import SelectInput from '../SelectInput';

export default function FilterInput({ edit, configure, indexName, filterInputs, setFilterInputs, isLoading, nbHits, index }) {
    const [inputFilterType, setInputFilterType] = useState('temp')

    return (
        <>
            <div className={`flex justify-center align-center ${edit ? "" : "hidden"}`}>
                <SelectInput
                    id="filterType"
                    inputName={inputFilterType}
                    setInputName={setInputFilterType}
                    options={
                        filterTypes[`${indexName}`]
                            ? [{ value: 'temp', name: '' }].concat(filterTypes[`${indexName}`].map(temp => ({ value: filterDecodes[`${temp}`], name: temp })))
                            : []
                    }
                />
            </div>
            <div className={`flex flex-row justify-center w-full mt-2 ${edit ? "" : "hidden"}`}>
                <FilterSelect
                    indexName={indexName}
                    inputFilterType={inputFilterType}
                    filterInputs={filterInputs}
                    setFilterInputs={setFilterInputs}
                    index={index}
                />
            </div>
            <div className={edit ? "" : "hidden"}>
                {
                    Object.keys(filterInputs).length
                        ? <FilterDisplay
                            filterInputs={filterInputs}
                            setFilterInputs={setFilterInputs}
                            index={index}
                        />
                        : <></>
                }
            </div>
            <div className={`w-full ${edit ? "hidden" : ""}`}>
                <p className="truncate">{configure.filters}</p>
            </div>
            <div className="pb-1 mt-1">
                <div className="flex flex-wrap items-baseline">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Filter hits : </h3>
                    {isLoading &&
                        <div className="ml-3 relative -bottom-1">
                            <Loader height="h-4" width="w-4" />
                        </div>
                    }
                    {!isLoading &&
                        <p className="ml-3 mt-1 text-lg font-semibold text-gray-500">
                            {nbHits[index]}
                        </p>
                    }
                </div>
            </div>
        </>
    )
}
