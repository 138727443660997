import React from 'react'
import { db } from '../config/firebase'
import { useEffect } from 'react'
import { useState } from 'react'
import { claimTypes } from '../utils/constants'


const getClaimStr = (claims, personIdx) => {
    let claimStr = '';
    claims[personIdx].forEach((claim, claimIdx) => {
        claimStr += claim
        claimStr += claimIdx + 1 === claims[personIdx].length ? '' : ' | ';
    })
    return claimStr;
}

function Users() {
    let [people, setPeople] = useState([])
    let [claims, setClaims] = useState([])
    let [inputClaim, setInputClaim] = useState('creator')

    useEffect(() => {
        (async () => {
            let tempDocs = (await db.collection('users').get()).docs
            let tempPeople = await Promise.all(tempDocs.map(async person => {
                let claims = (await db.collection('userClaims').doc(person.id).get()).data()
                let tempClaims = Object.keys(claims).filter(key => claims[key])
                let returnObject = {
                    id: person.id,
                    data: person.data(),
                    claims: tempClaims,
                    edit: false
                }
                return returnObject
            }))
            setPeople([...tempPeople])
        })()
    }, [])

    useEffect(() => setClaims(people.map(person => person.claims)), [people])

    return (
        <>
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="table-fixed w-full divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12"
                                        >
                                            Email
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12"
                                        >
                                            Username
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12"
                                        >
                                            Role
                                        </th>
                                        <th scope="col" className="relative px-6 py-3 w-2/12">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {people.map((person, personIdx) => (
                                        <tr key={person.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {person.data.email}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {person.data.username}
                                            </td>
                                            {
                                                !person.edit &&
                                                (
                                                    claims.length && claims[personIdx].length ?
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold">
                                                            {getClaimStr(claims, personIdx)}
                                                        </td> :
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            none
                                                        </td>
                                                )
                                            }
                                            {
                                                person.edit &&
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <label htmlFor="department" className="">
                                                        Claims
                                                    </label>
                                                    <select
                                                        required
                                                        type="text"
                                                        id="department"
                                                        value={inputClaim}
                                                        onChange={e => setInputClaim(e.target.value)}
                                                        className="ml-2 focus:ring-indigo-500 focus:border-indigo-500 relative inline-flex w-1/3 rounded-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                                    >
                                                        {
                                                            claimTypes.map(temp => <option key={temp} value={temp}>{temp}</option>)
                                                        }
                                                    </select>
                                                    <button
                                                        type="button"
                                                        className=" ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        onClick={() => {
                                                            setClaims(claims.map((temp, idx) => {
                                                                if (personIdx === idx) {
                                                                    if (!(temp.includes(inputClaim))) {
                                                                        temp.push(inputClaim);
                                                                    }
                                                                }
                                                                return temp
                                                            }))
                                                        }}
                                                    >
                                                        Add +
                                                    </button>
                                                    {
                                                        claims[personIdx].length &&
                                                        <div>
                                                            <div className="border-dashed border-4 border-blue-500 p-1 mt-2">
                                                                {
                                                                    claims[personIdx].map(claim => {
                                                                        return (
                                                                            <button
                                                                                key={claim}
                                                                                type="button"
                                                                                className="mr-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 hover:line-through focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                                                                onClick={() => setClaims(claims.map((temp, idx) => {
                                                                                    if (personIdx === idx) {
                                                                                        temp = temp.filter(tempValue => tempValue !== claim)
                                                                                    }
                                                                                    return temp;
                                                                                }))}
                                                                            >
                                                                                {claim}
                                                                            </button>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </td>
                                            }
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                {
                                                    !person.edit &&
                                                    <button
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                        onClick={() => setPeople(people.map((temp, tempIdx) => tempIdx === personIdx ? { ...temp, edit: true } : temp))}
                                                    >
                                                        Edit claims
                                                    </button>
                                                }
                                                {
                                                    person.edit &&
                                                    <button
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                        onClick={
                                                            async () => {
                                                                let tempPeople = people.map((temp, tempIdx) => {
                                                                    return tempIdx === personIdx ? { ...temp, claims: claims[personIdx], edit: false } : temp
                                                                })
                                                                let storingObject = {};
                                                                claimTypes.forEach(tempClaim => tempPeople[personIdx].claims.includes(tempClaim) ? storingObject[`${tempClaim}`] = true : storingObject[`${tempClaim}`] = false);
                                                                try {
                                                                    await db.collection('userClaims').doc(person.id).set(storingObject, { merge: true });
                                                                } catch (error) {
                                                                    console.log(`Error message :: ${error.message}`);
                                                                }
                                                                setPeople([...tempPeople])
                                                            }
                                                        }
                                                    >
                                                        Save
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users
