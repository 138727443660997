import React from 'react'
import { useSearchBox } from 'react-instantsearch'

export const CustomSeachBox = (props) => {
    const { currentRefinement, refine } = useSearchBox(props)
    return (
        <>
            <label htmlFor="search" className="sr-only">
                Search
            </label>
            <input
                type="search"
                id="search"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-400 rounded-md"
                value={currentRefinement}
                onChange={event => refine(event.currentTarget.value)}
            // onFocus={e => setSearch(true)}
            />
        </>
    )
}
