import React, { useState } from 'react'

export default function TitleInput({ edit, title, titleInput, setTitleInput, index }) {
    const [input, setInput] = useState(title);
    return (
        edit ?
            <>
                <input
                    required
                    type="text"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={input}
                    onChange={e => {
                        setTitleInput({ ...titleInput, [`${index}`]: e.target.value })
                        setInput(e.target.value)
                    }}
                />
            </> :
            <div className="w-full">
                <p className="truncate">
                    {title}
                </p>
            </div>
    )
}
