import React from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { Link } from "react-router-dom";
// import client from "../config/algolia";
import { createClient } from "contentful";

const contClient = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

let refineText = (field) => field.replace(/[^A-Za-z0-9-_]/g, "");
const getHashTags = (keywords) =>
    keywords?.map((keyword) => `#${(refineText(keyword))}`) ?? [];

function BlogPosts() {
    const [posts, setPosts] = useState([]);
    const [availablePosts, setAvailablePosts] = useState([]);
    const [presentPosts, setPresentPosts] = useState([]);
    const [newBlogPost, setNewBlogPost] = useState(false);
    const [inputBlog, setInputBlog] = useState({});

    const updateBlogPost = async (post) => {
        let updatedBlog = posts.find(
            (tempPost) =>
                tempPost.sys.id ===
                post.sys.id
        );
        if (updatedBlog === undefined) {
            await db
                .collection("BlogPosts")
                .doc(post.sys.id)
                .delete();
        } else {
            if (updatedBlog.style === "3") {
                if (updatedBlog.displayVideo)
                    updatedBlog.displayVideo.embedUrl =
                        getEmbedUrl(
                            updatedBlog.displayVideo
                        );
                if (updatedBlog.coverVideo)
                    updatedBlog.coverVideo.embedUrl =
                        getEmbedUrl(
                            updatedBlog.coverVideo
                        );
            }
            let tags = getHashTags(
                updatedBlog.keywords
            );
            await db
                .collection("BlogPosts")
                .doc(post.sys.id)
                .set({
                    ...updatedBlog,
                    tags: tags,
                    id: post.sys.id
                });

            let savingHit = {
                link: updatedBlog.link,
                title: updatedBlog.title,
                orientation:
                    updatedBlog.orientation,
                style: updatedBlog.style,
                shortDesc:
                    updatedBlog.shortDesc,
                objectID:
                    updatedBlog.sys.id,
                displayImage:
                    updatedBlog.displayImage,
                _tags: tags
            };
            if (savingHit.style === "3") {
                savingHit.displayVideo =
                    updatedBlog.displayVideo;
            }
        }
    }

    const addBlogPost = async () => {
        setNewBlogPost(false);
        setPresentPosts([
            ...presentPosts,
            inputBlog
        ]);
        setAvailablePosts(
            availablePosts.filter(
                (tempPost) => tempPost.sys.id !== inputBlog.sys.id
            )
        );
        let tags = getHashTags(inputBlog.keywords);
        let savingHit = {
            link: inputBlog.link,
            title: inputBlog.title,
            orientation: inputBlog.orientation,
            style: inputBlog.style,
            shortDesc: inputBlog.shortDesc,
            objectID: inputBlog.sys.id,
            displayImage: inputBlog.displayImage,
            _tags: tags
        };
        if (savingHit.style === "3") {
            savingHit.displayVideo =
                inputBlog.displayVideo;
        }
        await db
            .collection("BlogPosts")
            .doc(inputBlog.sys.id)
            .set({
                ...inputBlog,
                tags: tags,
                id: inputBlog.sys.id
            });
    }

    const getEmbedUrl = (videoembed) => {
        return videoembed?.provider === "youtube"
            ? videoembed.url.replace("watch?v=", "embed/")
            : videoembed?.provider === "vimeo"
                ? videoembed.url.replace("vimeo.com/", "player.vimeo.com/video/")
                : "";
    };

    useEffect(() => {
        (async () => {
            let presentPosts = (await db.collection("BlogPosts").get()).docs;
            let presentPostIds = presentPosts.map((post) => post.id);
            let newPosts = posts.filter(
                (post) => !presentPostIds.includes(post.sys.id)
            );
            let oldPosts = posts.filter((post) =>
                presentPostIds.includes(post.sys.id)
            );
            setAvailablePosts(newPosts);
            setPresentPosts(oldPosts);
        })();
    }, [posts]);

    useEffect(() => {
        setInputBlog(availablePosts[0]);
    }, [availablePosts]);

    useEffect(() => {
        (async () => {
            const response = await contClient.getEntries();
            let resPosts = response.items.map((post) => {
                return { ...post, ...post.fields };
            });

            setPosts(resPosts);
        })();
    }, []);
    return (
        <>
            <table className="divide-gray-200 table-fixed w-full">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-1/8"
                        >
                            ID
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-3/8"
                        >
                            Title
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-2/8 flex justify-center"
                        >
                            Display Picture
                        </th>
                        <th scope="col" className="relative px-2 py-3 w-2/8">
                            <span className="sr-only">
                                Update/Preview/Delete
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {presentPosts &&
                        presentPosts.map((post, postIdx) => (
                            <tr
                                key={post.sys.id}
                                className={
                                    postIdx % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                }
                            >
                                <td className="px-2 py-4 text-sm font-medium text-gray-900">
                                    {post.sys.id}
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    {post.title}
                                </td>
                                <td className="px-2 py-2 text-sm text-gray-500 flex justify-center">
                                    <img
                                        alt={post.displayImage?.fields.title}
                                        src={post.displayImage?.fields?.file?.url}
                                        className="h-24"
                                    ></img>
                                </td>
                                <td className="px-2 py-4 text-right text-sm font-medium">
                                    <button
                                        className="text-indigo-600 hover:text-indigo-900 mx-8"
                                        onClick={async () => await updateBlogPost(post)}
                                    >
                                        Update
                                    </button>
                                    <button className="text-indigo-600 hover:text-indigo-900 mx-8">
                                        <Link to={`/blogposts/${post.link}`}>
                                            preview
                                        </Link>
                                    </button>
                                    <button
                                        className="text-indigo-600 hover:text-indigo-900 mx-8"
                                        onClick={async () => {
                                            setNewBlogPost(false);
                                            setPresentPosts(
                                                presentPosts.filter(
                                                    (tempPost) =>
                                                        post.sys.id !==
                                                        tempPost.sys.id
                                                )
                                            );
                                            setAvailablePosts([
                                                ...availablePosts,
                                                post
                                            ]);
                                            await db
                                                .collection("BlogPosts")
                                                .doc(post.sys.id)
                                                .delete();
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    {newBlogPost && (
                        <tr colSpan="4">
                            <td colSpan="4">
                                <div className="flex justify-center align-center mx-8">
                                    <div
                                        className={`flex justify-center ${availablePosts.length
                                            ? "mt-8 pt-2"
                                            : ""
                                            }`}
                                    >
                                        <label
                                            htmlFor="Blog"
                                            className="mr-2 mt-1 align-center font-bold text-lg"
                                        >
                                            Blog
                                        </label>
                                    </div>
                                    <div className="w-1/4">
                                        <Select
                                            inputId="Blog"
                                            className="relative inline-flex rounded-md bg-transparent w-full focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm border-gray-300"
                                            value={inputBlog}
                                            options={availablePosts.map(
                                                (tempPost, postIdx) => ({
                                                    ...tempPost,
                                                    value: postIdx
                                                })
                                            )}
                                            formatOptionLabel={(post) => (
                                                <div className="flex justify-center">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="px-2 py-2 text-sm text-gray-500 flex justify-center">
                                                                    <img
                                                                        alt={
                                                                            post
                                                                                ?.displayImage
                                                                                ?.title
                                                                        }
                                                                        src={
                                                                            post
                                                                                ?.displayImage
                                                                                ?.url
                                                                        }
                                                                        className="h-24"
                                                                    ></img>
                                                                </td>
                                                                <td className="px-2 py-4 text-sm text-gray-500">
                                                                    {post.title}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            onChange={(e) => {
                                                if (e.style === "3") {
                                                    e.displayVideo.embedUrl =
                                                        getEmbedUrl(
                                                            e.displayVideo
                                                        );
                                                    e.coverVideo.embedUrl =
                                                        getEmbedUrl(
                                                            e.coverVideo
                                                        );
                                                }
                                                setInputBlog({ ...e });
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`h-full ${availablePosts.length
                                            ? "mt-8 pt-2"
                                            : ""
                                            }`}
                                    >
                                        {inputBlog && <button
                                            className="mx-2 inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={addBlogPost}
                                        >
                                            Add
                                        </button>}
                                    </div>
                                    <div
                                        className={`h-full ${availablePosts.length
                                            ? "mt-8 pt-2"
                                            : ""
                                            }`}
                                    >
                                        <button
                                            className="my-2 ml-6 px-2 border border-indigo-600 rounded-full"
                                            onClick={async () => {
                                                setNewBlogPost(false);
                                            }}
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan="4">
                            <div className="w-full flex justify-center mt-6">
                                <div>
                                    <button
                                        type="button"
                                        className="mx-2 inline-flex items-center px-4 py-2 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() => setNewBlogPost(true)}
                                    >
                                        Add New Blog Post
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default BlogPosts;
