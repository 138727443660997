import React from 'react'

function Home() {
    return (
        <div>
            <h2>It's home</h2>
        </div>
    )
}

export default Home
