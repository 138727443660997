import { useEffect, useState } from 'react';
import { useHits } from 'react-instantsearch';
import { decodeFilters, getFilterStr } from '../../utils/constants';
import OrderInput from './inputs/carousels/OrderInput';
import TitleInput from './inputs/carousels/TitleInput';
import IndexNameInput from './inputs/carousels/IndexNameInput';
import StyleInput from './inputs/carousels/StyleInput';
import SelectFilterInput from './inputs/carousels/SelectFilterInput';
import FilterInput from './inputs/carousels/FilterInput';

// let index = client.initIndex('carousels_config');

let refineText = field => field.replace(/[^A-Za-z0-9-_ ]/g, '')
let addCharInSpaces = (field, char) => field.replace(/[ ]/g, char)
let getLink = title => {
    return addCharInSpaces(refineText(title), '-')
}

const fillObject = (arr, fn) => {
    let newObj = {}
    arr.forEach(element => {
        newObj[element.objectID] = fn(element)
    });
    return newObj
}

export const CustomHits = (props) => {
    const { hits } = useHits(props)
    const [tempCount, setTempCount] = useState(0);
    const [displayHits, setDisplayHits] = useState([])
    const [indexNameInput, setIndexNameInput] = useState({});
    const [sortMethodsName, setSortMethodsName] = useState({})
    const [sortDirection, setSortDirection] = useState({});
    const [titleInput, setTitleInput] = useState({})
    const [orderInput, setOrderInput] = useState({})
    const [filterInputs, setFilterInputs] = useState({})
    const [carouselType, setCarouselType] = useState({});
    const [carouselStyle, setCarouselStyle] = useState({});
    const [personalization, setPersonalization] = useState({});
    const [visibility, setVisibility] = useState({});
    const [nbHits, setNbHits] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setDisplayHits(hits.map(hit => {
            hit.edit = false;
            return hit;
        }))
    }, [hits])

    useEffect(() => {
        setIndexNameInput(fillObject(displayHits, hit => hit.indexName.split('_')[0]))
        setSortMethodsName(fillObject(displayHits, hit => hit.indexName.split('_')[1]))
        setSortDirection(fillObject(displayHits, hit => hit.indexName.split('_')[2]))
        setTitleInput(fillObject(displayHits, hit => hit.title))
        setOrderInput(fillObject(displayHits, hit => hit.order))
        setCarouselType(fillObject(displayHits, hit => hit.type))
        setCarouselStyle(fillObject(displayHits, hit => hit.style))
        setFilterInputs(fillObject(displayHits, hit => decodeFilters(hit.configure.filters)))
        setPersonalization(fillObject(displayHits, hit => hit.personalization === undefined ? false : hit.personalization))
        setVisibility(fillObject(displayHits, hit => hit.visibility === undefined ? false : hit.visibility))
    }, [displayHits])

    // useEffect(() => {
    //     (async () => {
    //         setIsLoading(true);
    //         let newNBHits = {}
    //         for (const key in filterInputs) {
    //             if (Object.hasOwnProperty.call(filterInputs, key)) {
    //                 const element = filterInputs[key];
    //                 // let tempIndex = client.initIndex(indexNameInput[key])
    //                 // let result = await tempIndex.search('', {
    //                 //     filters: getFilterStr(element),
    //                 //     facets: '*'
    //                 // })
    //                 newNBHits[key] = result.nbHits
    //             }
    //         }
    //         setNbHits({ ...newNBHits })
    //         setIsLoading(false)
    //     })()
    // }, [filterInputs, indexNameInput])

    return (
        <div className="flex flex-col">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <div className="my-4 w-full flex justify-center">
                    <div>
                        <button
                            type="button"
                            className="mx-2 inline-flex items-center px-4 py-2 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                                setDisplayHits([
                                    {
                                        order: displayHits.length ? displayHits[displayHits.length - 1].order + 1 : 0,
                                        title: "",
                                        indexName: "Movie_awards_desc",
                                        configure: {
                                            filters: "",
                                            hitsPerPage: 10
                                        },
                                        visibility: true,
                                        personalization: false,
                                        type: "portrait",
                                        style: 1,
                                        edit: true,
                                        objectID: `temp${tempCount}`
                                    },
                                    ...displayHits
                                ])
                                setTempCount(tempCount + 1)
                            }}
                        >
                            Add New Carousel
                        </button>
                    </div>
                </div>
                <table className="divide-gray-200 table-fixed w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"
                            >
                                Order
                            </th>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12"
                            >
                                Title
                            </th>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"
                            >
                                Index
                            </th>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"
                            >
                                Style
                            </th>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"
                            >
                                Personalization
                            </th>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"
                            >
                                Visibility
                            </th>
                            <th
                                scope="col"
                                className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12"
                            >
                                Filters
                            </th>
                            <th scope="col" className="relative px-2 py-3 w-1/12">
                                <span className="sr-only">Edit/Delete</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayHits && displayHits.map((hit, itemIdx) => (
                            <tr key={hit.objectID} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-2 py-4 text-sm font-medium text-gray-900">
                                    <OrderInput
                                        edit={hit.edit}
                                        order={hit.order}
                                        orderInput={orderInput}
                                        setOrderInput={setOrderInput}
                                        index={hit.objectID}
                                    />
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    <TitleInput
                                        edit={hit.edit}
                                        title={hit.title}
                                        titleInput={titleInput}
                                        setTitleInput={setTitleInput}
                                        index={hit.objectID}
                                    />
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    <IndexNameInput
                                        edit={hit.edit}
                                        indexName={hit.indexName}
                                        indexNameInput={indexNameInput}
                                        sortMethodsName={sortMethodsName}
                                        sortDirection={sortDirection}
                                        carouselType={carouselType}
                                        setIndexNameInput={setIndexNameInput}
                                        setSortMethodsName={setSortMethodsName}
                                        setSortDirection={setSortDirection}
                                        setCarouselType={setCarouselType}
                                        index={hit.objectID}
                                    />
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    <StyleInput
                                        edit={hit.edit}
                                        type={hit.type}
                                        style={hit.style}
                                        indexName={indexNameInput}
                                        carouselStyle={carouselStyle}
                                        setCarouselStyle={setCarouselStyle}
                                        carouselType={carouselType}
                                        setCarouselType={setCarouselType}
                                        index={hit.objectID}
                                    />
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    {
                                        hit.edit ?
                                            <SelectFilterInput
                                                id="personalization"
                                                inputName={personalization}
                                                setInputName={setPersonalization}
                                                options={[
                                                    { value: true, name: "on" },
                                                    { value: false, name: "off" }
                                                ]}
                                                index={hit.objectID}
                                            />
                                            : hit.personalization === 'true' || hit.personalization === true ? 'on' : hit.personalization === 'false' || hit.personalization === false ? 'off' : ''
                                    }
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    {
                                        hit.edit
                                            ? <SelectFilterInput
                                                id="visibility"
                                                inputName={visibility}
                                                setInputName={setVisibility}
                                                options={[
                                                    { value: true, name: "on" },
                                                    { value: false, name: "off" }
                                                ]}
                                                index={hit.objectID}
                                            />
                                            : hit.visibility === 'true' || hit.visibility === true ? 'on' : hit.visibility === 'false' || hit.visibility === false ? 'off' : ''
                                    }
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-500">
                                    <FilterInput
                                        edit={hit.edit}
                                        configure={hit.configure}
                                        indexName={hit.indexName.split("_")[0]}
                                        filterInputs={filterInputs}
                                        setFilterInputs={setFilterInputs}
                                        isLoading={isLoading}
                                        nbHits={nbHits}
                                        index={hit.objectID}
                                    />
                                </td>
                                <td className="px-2 py-4 text-right text-sm font-medium">
                                    <button
                                        className="text-indigo-600 mr-8 hover:text-indigo-900"
                                        onClick={() => {
                                            // let newHits = []
                                            // newHits = displayHits.filter(tempHit => {
                                            //     if (tempHit.objectID === hit.objectID) {
                                            //         if (tempHit.objectID !== 'temp') {
                                            //             (async () => await index.deleteObject(tempHit.objectID))()
                                            //         }
                                            //         return false;
                                            //     } else {
                                            //         return true;
                                            //     }
                                            // }).sort((a, b) => a.order - b.order)
                                            // setDisplayHits([...newHits]);
                                        }}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="text-indigo-600 hover:text-indigo-900"
                                        onClick={() => {
                                            let newHits = []
                                            if (hit.edit) {
                                                newHits = displayHits.map(tempHit => {
                                                    if (hit.objectID === tempHit.objectID) {
                                                        tempHit.indexName = `${indexNameInput[hit.objectID]}_${sortMethodsName[hit.objectID]}_${sortDirection[hit.objectID]}`
                                                        tempHit.title = titleInput[hit.objectID]
                                                        tempHit.order = orderInput[hit.objectID]
                                                        let filterStr = getFilterStr(filterInputs[hit.objectID]);
                                                        tempHit.configure.filters = filterStr;
                                                        tempHit.type = carouselType[hit.objectID]
                                                        tempHit.style = carouselStyle[hit.objectID];
                                                        tempHit.personalization = personalization[hit.objectID];
                                                        tempHit.visibility = visibility[hit.objectID]
                                                        tempHit.link = getLink(hit.title)
                                                        tempHit.edit = false
                                                        let savingHit = {
                                                            order: tempHit.order,
                                                            title: tempHit.title,
                                                            link: tempHit.link,
                                                            configure: tempHit.configure,
                                                            indexName: tempHit.indexName,
                                                            type: tempHit.type,
                                                            style: tempHit.style,
                                                            visibility: tempHit.visibility === 'true' || tempHit.visibility === true ? true : tempHit.visibility === 'false' || tempHit.visibility === false ? false : false,
                                                            personalization: tempHit.personalization === 'true' || tempHit.personalization === true ? true : tempHit.personalization === 'false' || tempHit.personalization === false ? false : false
                                                        };
                                                        console.log(`objectID :: ${JSON.stringify(tempHit.objectID, null, 4)}`);
                                                        if (!(tempHit.objectID.includes('temp'))) {
                                                            savingHit.objectID = tempHit.objectID;
                                                        }
                                                        // (async () => {
                                                        //     let { objectID } = await index.saveObject(savingHit, {
                                                        //         autoGenerateObjectIDIfNotExist: true
                                                        //     })
                                                        //     tempHit.objectID = objectID
                                                        // })()
                                                    }
                                                    return tempHit;
                                                }).sort((a, b) => a.order - b.order)
                                            } else {
                                                newHits = displayHits.map(tempHit => {
                                                    if (hit.objectID === tempHit.objectID)
                                                        tempHit.edit = true;
                                                    return tempHit;
                                                })
                                            }
                                            setDisplayHits([...newHits]);
                                        }}
                                    >
                                        {hit.edit ? 'Save' : 'Edit'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}